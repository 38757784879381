<template>
	<div
	v-if="can('order.total_vendido')">
		<div class="cont-total-pedidos-eliminados">
			<div 	
			class="total">
				<span>
					{{ price(total_all_orders) }}
				</span>
			</div>

			<b-button
			v-if="is_owner"
			@click="see_deleted_orders"
			variant="outline-danger"> 
				<b-badge
				v-if="deleted_orders.length"
				variant="danger">
					{{ deleted_orders.length }}	 
				</b-badge>
				Eliminados
			</b-button>
		</div>
		<p
		class="title">
			Metodos de pago utilizados 
		</p>
		<payment-methods-table></payment-methods-table>
	</div>
</template>
<script>
import orders from '@/mixins/orders'
export default {
	mixins: [orders],
	components: {
		PaymentMethodsTable: () => import('@/components/orders/components/list/total-resume/PaymentMethodsTable'),	
	},
	computed: {
		deleted_orders() {
			return this.$store.state.order.deleted_models
		},
	},
	methods: {
		see_deleted_orders() {
			this.$store.dispatch('order/getDeletedModels')
			this.$bvModal.show('deleted-orders')
		}
	}
}
</script>
<style lang="sass">
.cont-total-pedidos-eliminados
	display: flex 
	flex-direction: row 
	justify-content: space-between
	align-items: center

	.total 
		margin: 1em 0
		font-size: 40px
		font-weight: bold
</style>